export const SOCIAL_NETWORKS = {
    tg: {
        name: "Telegram",
        href: "https://t.me/stableunit",
    },
    discord: {
        name: "Discord",
        href: "https://discord.gg/WrCrCW6F4c",
    },
    medium: {
        name: "Medium",
        href: "https://blog.stableunit.org/",
    },
    twitter: {
        name: "Twitter",
        href: "https://twitter.com/stableunitdao",
    },
    github: {
        name: "Github",
        href: "https://github.com/StableUnit",
    },
};
